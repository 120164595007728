const darkModeBtns = document.querySelectorAll('.dark-mode-button');
const nav = document.querySelector('.nav');

// WHen the page loads, check immediately the darkMode localStorage
let theme = localStorage.getItem('theme');
let date = new Date();
let hours = date.getHours();
let minutes = date.getMinutes();
const body = document.body;
const darkModeIconContainers = document.querySelectorAll('.dark-mode-icon--container');
const lightModeIconContainers = document.querySelectorAll('.light-mode-icon--container');

// Tasks
// Check if darkmode is enabled
// if it's enabled turn it off
// if it's disabled turn it on

const enableDarkMode = () => {
	document.body.classList.add('dark-mode');
	localStorage.setItem('theme', 'dark');
};

const disableDarkMode = () => {
	document.body.classList.remove('dark-mode');
	localStorage.setItem('theme', 'light');
};

// Check mo agad yung localStorage kung may darkMode na key tapos kung naka enable, pag naka enable, invoke the func
function checkTheme() {
	// Check the user preference
	if (theme === 'dark') {
		return enableDarkMode();
	} else if (theme === 'light') {
		return disableDarkMode();

		// If walang user preference, or 1st time, check mo nalang yung hours
	} else {
		// Check the hours
		if (hours < 6 || hours >= 18) {
			enableDarkMode();
		} else {
			disableDarkMode();
		}
	}
}

checkTheme();

const themeBtnSwitch = () => {
	if (body.classList.contains('dark-mode')) {
		lightModeIconContainers.forEach((el) => {
			el.style.transform = 'translateY(0%)';
		});

		darkModeIconContainers.forEach((el) => {
			el.style.transform = 'translateY(-100%)';
		});
	} else {
		lightModeIconContainers.forEach((el) => {
			el.style.transform = 'translateY(100%)';
		});

		darkModeIconContainers.forEach((el) => {
			el.style.transform = 'translateY(0%)';
		});
	}
};

darkModeBtns.forEach((btn) => {
	btn.addEventListener('click', () => {
		// Pag nag click ng button
		// Dapat bago mo icheck kung enabled ang dark mode check mo muna kung anong laman ng darkMode localStorage
		// Then doon ka mag compare
		theme = localStorage.getItem('theme');

		if (theme !== 'dark') {
			enableDarkMode();
		} else {
			disableDarkMode();
		}

		themeBtnSwitch();
	});
});

// Navbar add shadow
window.addEventListener('scroll', () => {
	nav.classList.toggle('scrolled', window.scrollY > 0);
});

themeBtnSwitch();
